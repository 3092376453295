import React, { memo } from "react";

import SearchSVG from "modules/theme/icons/form/inputs/search.svg";
import { InputFormat } from "../constants";

type InputIconProps = {
  format: InputFormat;
};

const InputIcon: React.FC<InputIconProps> = ({ format }) => {
  switch (format) {
    case InputFormat.SEARCH:
      return <SearchSVG />;
    default:
      return null;
  }
};

export default memo(InputIcon);
