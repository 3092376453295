import React from "react";
import clsx from "clsx";

import * as styles from "./styles.module.scss";

type LabelProps = {
  children?: React.ReactNode;
  id?: string;
  hasStickyLabel?: boolean;
  isSticky?: boolean;
  label: string;
  onClick?: (event: React.MouseEvent<HTMLLabelElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLLabelElement>) => void;
};

const Label: React.FC<LabelProps> = ({ children, id, hasStickyLabel, isSticky, label, onClick, onKeyDown }) => {
  const className = clsx(styles.label, hasStickyLabel && styles.stickyLabel, isSticky && styles.isSticky);
  return (
    <label
      htmlFor={id}
      className={className}
      onClick={onClick}
      onKeyDown={onKeyDown}
      onMouseDown={onKeyDown ? (event) => event.preventDefault() : undefined}
      tabIndex={onKeyDown ? 0 : -1}
    >
      {label}
      {children}
    </label>
  );
};

export default React.memo(Label);
