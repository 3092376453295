import React from "react";

import { InputStringFormat } from "../constants";
import { InputStringProps } from "../types";
import InputPrimitive from "../Primitive";

const InputString: React.FunctionComponent<InputStringProps> = ({
  autoFocus,
  disabled,
  forwardedRef,
  format = InputStringFormat.TEXT,
  id,
  invalid,
  label,
  maxLength,
  name,
  onBlur,
  onChange,
  onFocus,
  onInput,
  onKeyDown,
  placeholder,
  readOnly,
  style,
  tabIndex,
  value,
}) => {
  const getTabIndex = tabIndex ? tabIndex : 0;
  return (
    <InputPrimitive
      autoFocus={!disabled && autoFocus}
      disabled={disabled}
      format={format}
      forwardedRef={forwardedRef}
      id={id}
      invalid={invalid}
      label={label}
      maxLength={maxLength}
      name={name}
      placeholder={placeholder}
      onBlur={onBlur}
      onChange={onChange}
      onFocus={onFocus}
      onInput={onInput}
      onKeyDown={onKeyDown}
      readOnly={readOnly}
      style={style}
      tabIndex={disabled ? -1 : getTabIndex}
      value={value || ""}
    />
  );
};

export default React.memo(InputString);
