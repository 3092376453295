type useParamsProps = {
  activeCertifications: string[];
  name: string;
  familyName: string;
  candidateNumber: string;
  records: number;
  offset: number;
};

export const useParams = ({
  activeCertifications,
  name,
  familyName,
  candidateNumber,
  records,
  offset,
}: useParamsProps) => {
  return {
    first_name: name ? name : null,
    surname: familyName ? familyName : null,
    candidate_number: candidateNumber ? candidateNumber : null,
    scheme: !!activeCertifications.length ? activeCertifications.map((activeItem) => activeItem).join(",") : null,
    numberPerPage: records,
    offset: offset,
  };
};
