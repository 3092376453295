import { SCRSchemeItemType } from "modules/api/endpoints/scr-scheme";
import { useDataLayer } from "modules/gtm";

type useGtmTriggerProps = {
  name: string;
  familyName: string;
  certifications: SCRSchemeItemType[];
  activeCertifications: string[];
  candidateNumber: string;
};

export const useGtmTrigger = ({
  name,
  familyName,
  certifications,
  activeCertifications,
  candidateNumber,
}: useGtmTriggerProps) => {
  const gtmCertification = certifications.find((i) => i.id === activeCertifications.toString())?.title;
  const cleanName = name ? `${name}` : null;
  const cleanFamilyName = familyName ? `${familyName}` : null;
  const cleanCertifications = gtmCertification ? gtmCertification : null;
  const cleanCandidateNumber = candidateNumber ? `${candidateNumber}` : null;
  const gtmParams = [cleanName, cleanFamilyName, cleanCertifications, cleanCandidateNumber].filter((i) => i !== null);

  return useDataLayer({
    action: "Search",
    category: "Successful Candidates Register",
    label: gtmParams.length === 1 ? gtmParams.join("") : gtmParams.join(", "),
  });
};
