import { DropdownType } from "components/DropdownSelect/constants";
import { SCRSchemeItemType } from "modules/api/endpoints/scr-scheme";
import { useEffect } from "react";

type useDropdownProps = {
  SCRSchemes: SCRSchemeItemType[] | null;
  setActiveCertifications: (value: React.SetStateAction<string[]>) => void;
  setCertifications: (value: React.SetStateAction<SCRSchemeItemType[]>) => void;
};

export const useDropdown = ({
  SCRSchemes,
  setActiveCertifications,
  setCertifications,
}: useDropdownProps) => {
  useEffect(() => {
    if (!SCRSchemes) return;
    setCertifications(() => {
      return SCRSchemes.map((certification) => certification).sort((a, b) => a.title.localeCompare(b.title));
    });
  }, [SCRSchemes]);

  const getActiveIds = (arr: string[], id: string) => {
    if (!id) return [];
    if (arr.includes(id)) {
      return arr.filter((activeId) => activeId !== id);
    }
    return [...arr, id];
  };

  const handleActiveFilteredItems = async (id: string, type: DropdownType) => {
    if (type === DropdownType.CERTIFICATION) {
      setActiveCertifications((activeIds) => {
        return getActiveIds(activeIds, id);
      });
    }
  };

  return { handleActiveFilteredItems };
};
