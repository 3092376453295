import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { graphql } from "gatsby";
import { useFetchSCRData } from "components/SCR/hooks/fetchScrData";
import { useFetchScrSchemesData } from "components/SCR/hooks/fetchScrSchemesData";
import { useResetFilter } from "components/SCR/hooks/resetFilters";
import { useInputChanges } from "components/SCR/hooks/inputChanges";
import { useDropdown } from "components/SCR/hooks/dropdown";
import { useValidateFilters } from "components/SCR/hooks/validateFilters";
import { useParams } from "components/SCR/hooks/params";
import { SCRItemType } from "modules/api/endpoints/scr";
import { SCRFilterPreviewProps } from "components/SCR/Filter/types";
import { SCRSchemeItemType } from "modules/api/endpoints/scr-scheme";
import { DropdownType } from "components/DropdownSelect/constants";
import { DegreesBannerMediumProps } from "components/SixtyThreeDegreesBanner/Medium";
import { HeroBannerProps, FilterConfigurationProps } from "components/SCR/types";
import { ProductCardsProps } from "components/ProductCards/types";
import { useNewsletterPopup } from "components/Newsletter/hooks/newsletterPopup";
import { DefaultMetaType, MetaType } from "modules/meta/types";
import { useGtmTrigger } from "components/SCR/hooks/gtmTrigger";
import { NewsletterPopupType } from "modules/newsletter/types";

import * as SCR from "components/SCR";

export type SCRQueryTypes = {
  contentstackNewsletterPopup: NewsletterPopupType;
  contentstackGlobalMeta: DefaultMetaType;
  contentstackScrListPage: {
    title: string;
    meta: MetaType;
    newsletter: {
      show_popup_page: string;
    };
    hero_banner: HeroBannerProps;
    filterConfiguration: FilterConfigurationProps;
    product_cards: ProductCardsProps;
    medium_degrees_banner: DegreesBannerMediumProps;
  };
};

type SCRPageProps = { data: SCRQueryTypes; location: any };

const SCRPage: React.FC<SCRPageProps> = ({ data, location }) => {
  /* Newsletter Popup */
  const newsletter = data?.contentstackNewsletterPopup;
  const page = data?.contentstackScrListPage;
  /* Ajax Data */
  const [SCREntries, setSCREntries] = useState<SCRItemType[] | null>([]);
  const [SCRSchemes, setSCRSchemes] = useState<SCRSchemeItemType[] | null>([]);
  /* Input Filters */
  const [name, setName] = useState("");
  const [familyName, setFamilyName] = useState("");
  const [candidateNumber, setCandidateNumber] = useState("");
  const [activeCertifications, setActiveCertifications] = useState<string[]>([]);
  const nameRef = useRef<HTMLInputElement>(null);
  const familyNameRef = useRef<HTMLInputElement>(null);
  const candidateNumberRef = useRef<HTMLInputElement>(null);
  /* Dropdown Filter */
  const [certifications, setCertifications] = useState<SCRSchemeItemType[]>([]);
  /* Pagination Control */
  const [counter, updateCounter] = useState(0);
  const [records, setRecords] = useState(20);
  const [offset, setOffset] = useState(0);
  const [reset, setReset] = useState(false);
  const [skeleton, setSkeleton] = useState(false);
  const [totalRecords, setTotalRecords] = useState<number | null>(null);
  /* Search Button */
  const searchButtonRef = useRef<HTMLButtonElement>(null);
  const [searchClicked, setSearchClicked] = useState(false);
  /* Ajax States */
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  /* Validation Messages */
  const firstNameValidationMessage = data?.contentstackScrListPage?.filterConfiguration?.firstNameValidationMessage;
  const emptyMessage = data?.contentstackScrListPage?.filterConfiguration?.noResultsMessage;
  /* Results Ref */
  const resultsRef = useRef<HTMLDivElement>(null);

  /* Input Handlers */
  const { handleNameChange, handleFamilyNameChange, handleCandidateNameChange } = useInputChanges({
    setName,
    setFamilyName,
    setCandidateNumber,
  });

  // /* Dropdown Handlers */
  const { handleActiveFilteredItems } = useDropdown({
    SCRSchemes,
    setActiveCertifications,
    setCertifications,
  });

  useEffect(() => {
    useFetchScrSchemesData({ setSCRSchemes });
  }, []);

  // /* API Parameters */
  const params = useParams({ activeCertifications, name, familyName, candidateNumber, records, offset });

  // /* Filter Validation */
  const { validate } = useValidateFilters({
    activeCertifications,
    name,
    familyName,
    candidateNumber,
    setIsLoading,
    setTotalRecords,
    setSCREntries,
    setErrorMessage,
    emptyMessage,
    firstNameValidationMessage,
    setSkeleton,
  });

  // /* Search Button Handler */
  const handleSearchButtonClick = () => {
    setSearchClicked(true);
    setSkeleton(true);
  };

  useEffect(() => {
    if (!searchClicked) return;
    if (validate() && reset) {
      const fetchPaginatedData = false;
      useGtmTrigger({ name, familyName, certifications, activeCertifications, candidateNumber });
      useFetchSCRData({
        params,
        fetchPaginatedData,
        setIsLoading,
        setTotalRecords,
        setErrorMessage,
        setOffset,
        setSCREntries,
        emptyMessage,
        records,
        setReset,
        setSkeleton,
      });
    }
  }, [searchClicked, reset]);

  // /* Load More Button Handler */
  const handleLoadMoreClick = () => {
    setSearchClicked(false);
    updateCounter((currCounter) => currCounter + 1);
  };

  // /* Load Paginated Results */
  useEffect(() => {
    if (counter === 0) return;
    if (validate()) {
      const fetchPaginatedData = true;
      useFetchSCRData({
        params,
        fetchPaginatedData,
        setIsLoading,
        setTotalRecords,
        setErrorMessage,
        setOffset,
        setSCREntries,
        emptyMessage,
        records,
        setReset,
        setSkeleton,
      });
    }
  }, [counter]);

  // /* Reset Filter On Enter/Click With Filter Controls */
  useResetFilter({
    setRecords,
    setOffset,
    nameRef,
    familyNameRef,
    candidateNumberRef,
    searchButtonRef,
    setReset,
  });

  // Focus On Reults
  useLayoutEffect(() => {
    if (!isLoading && !searchClicked) return;
    if (isLoading && searchClicked && resultsRef && resultsRef.current) {
      resultsRef.current.scrollIntoView({
        behavior: "smooth",
      });
      setSearchClicked(false);
    }
  }, [isLoading, resultsRef, searchClicked]);

  useNewsletterPopup({
    config: newsletter?.config,
    copy: newsletter?.copy,
    submitted: newsletter?.submitted,
    pageOverride: page?.newsletter?.show_popup_page,
  });

  const filterProps: SCRFilterPreviewProps = {
    activeCertifications: activeCertifications,
    candidateNumber: candidateNumber,
    candidateNumberRef: candidateNumberRef,
    handleActiveFilteredItems: handleActiveFilteredItems,
    handleCandidateNameChange: handleCandidateNameChange,
    handleNameChange: handleNameChange,
    handleFamilyNameChange: handleFamilyNameChange,
    handleSearchButtonClick: handleSearchButtonClick,
    name: name,
    nameRef: nameRef,
    familyName: familyName,
    familyNameRef: familyNameRef,
    searchButtonRef: searchButtonRef,
    type: DropdownType.CERTIFICATION,
  };

  return (
    <SCR.Template
      defaultMeta={data.contentstackGlobalMeta}
      errorMessage={errorMessage}
      certifications={certifications}
      filterProps={filterProps}
      handleLoadMoreClick={handleLoadMoreClick}
      hero_banner={page?.hero_banner}
      isLoading={isLoading}
      location={location}
      mediumDegreesBanner={page?.medium_degrees_banner}
      meta={page?.meta}
      productCards={page?.product_cards}
      resultsRef={resultsRef}
      scrEntries={SCREntries}
      skeleton={skeleton}
      totalRecords={totalRecords}
    />
  );
};

export const query = graphql`
  query ($id: String) {
    contentstackNewsletterPopup {
      ...newsletterPopup
    }
    contentstackGlobalMeta {
      ...defaultMeta
    }
    contentstackScrListPage(id: { eq: $id }) {
      ...metaScrList
      newsletter {
        show_popup_page
      }
      hero_banner {
        content
        image {
          url
        }
        image_horizontal_position
      }
      filterConfiguration: filter_configuration {
        firstNameValidationMessage: first_name_validation_message
        noResultsMessage: no_results_message
      }
      ...productCardsSCR
      ...degreesBannerMediumSCR
    }
  }
`;

export default SCRPage;
