type useInputChangesProps = {
  setName: (value: React.SetStateAction<string>) => void;
  setFamilyName: (value: React.SetStateAction<string>) => void;
  setCandidateNumber: (value: React.SetStateAction<string>) => void;
};

export const useInputChanges = ({ setName, setFamilyName, setCandidateNumber }: useInputChangesProps) => {
  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };
  const handleFamilyNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFamilyName(event.target.value);
  };
  const handleCandidateNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCandidateNumber(event.target.value);
  };

  return { handleNameChange, handleFamilyNameChange, handleCandidateNameChange };
};
