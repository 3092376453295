import { API, api } from "modules/api";
import { SCRItemType, SCRGetResponse } from "modules/api/endpoints/scr";

type RecordsProps = {
  first_name: string | null;
  surname: string | null;
  candidate_number: string | null;
  scheme: string | null;
  numberPerPage: number;
  offset: number;
};

type useFetchSCRDataProps = {
  params: RecordsProps;
  fetchPaginatedData: boolean;
  setIsLoading: (value: React.SetStateAction<boolean>) => void;
  setTotalRecords: (value: React.SetStateAction<number | null>) => void;
  setErrorMessage: (value: React.SetStateAction<string | null>) => void;
  setOffset: (value: React.SetStateAction<number>) => void;
  setSCREntries: (value: React.SetStateAction<SCRItemType[] | null>) => void;
  emptyMessage: string;
  records: number;
  setReset: React.Dispatch<React.SetStateAction<boolean>>;
  setSkeleton: React.Dispatch<React.SetStateAction<boolean>>;
};

export const useFetchSCRData = async ({
  params,
  fetchPaginatedData,
  setIsLoading,
  setTotalRecords,
  setErrorMessage,
  setOffset,
  setSCREntries,
  emptyMessage,
  records,
  setReset,
  setSkeleton,
}: useFetchSCRDataProps) => {
  !fetchPaginatedData && setReset(false);
  try {
    const scrResponse = await api(API.GET_SCR(params)).then((res: SCRGetResponse) => res.data);
    if (scrResponse.records.length === 0) {
      // No records
      setIsLoading(false);
      setSkeleton(false);
      setTotalRecords(0);
      setErrorMessage(emptyMessage);
      return;
    }
    // Successful
    setIsLoading(false);
    setSkeleton(false);
    setTotalRecords(scrResponse.totalRecords);
    if (fetchPaginatedData) {
      setOffset((currOffset) => currOffset + records);
      setSCREntries((currEntries) => {
        if (currEntries) {
          return [...currEntries, ...scrResponse.records];
        }
        return scrResponse.records;
      });
    } else {
      setOffset(records);
      setSCREntries(scrResponse.records);
    }
    setErrorMessage(null);
  } catch (error) {
    // Error
    setIsLoading(false);
    setSkeleton(false);
    setTotalRecords(0);
    setErrorMessage(`${error}.`);
  }
};
