import React from "react";

import * as Button from "components/Button";
import { ButtonStyle } from "components/Button/constants";
import * as DropdownSelect from "components/DropdownSelect";
import * as Input from "components/Inputs/Input";
import { InputFormat, InputStyle } from "components/Inputs/Input/constants";
import LoadingSVG from "modules/theme/icons/loading/button-loading.svg";
import { SCRFilterProps } from "./types";

import * as styles from "./styles.module.scss";

const Filter: React.FC<SCRFilterProps> = ({
  activeCertifications,
  candidateNumber,
  candidateNumberRef,
  certifications,
  forwardedContainerRef,
  handleActiveFilteredItems,
  handleCandidateNameChange,
  handleNameChange,
  handleSearchButtonClick,
  handleFamilyNameChange,
  isLoading,
  name,
  nameRef,
  searchButtonRef,
  familyName,
  familyNameRef,
  type,
}) => {
  const handleEnterKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      handleSearchButtonClick();
    }
  };
  return (
    <div className={styles.scrFilter}>
      <div>
        <Input.String
          id="scRegisterName"
          label="Name"
          forwardedRef={nameRef}
          name="scRegisterName"
          onChange={handleNameChange}
          onKeyDown={handleEnterKeyDown}
          value={name}
        />
        <Input.String
          id="scRegisterFamilyName"
          label="Last Name"
          forwardedRef={familyNameRef}
          name="scRegisterFamilyName"
          onChange={handleFamilyNameChange}
          onKeyDown={handleEnterKeyDown}
          value={familyName}
        />
        <DropdownSelect.Multiple
          activeItems={activeCertifications}
          forwardedContainerRef={forwardedContainerRef}
          items={certifications}
          label="Clear"
          multiple={false}
          name="Certification"
          setActiveItems={handleActiveFilteredItems}
          type={type}
        />
      </div>
      <div>
        <Input.String
          format={InputFormat.SEARCH}
          id="scRegisterCandidateNumber"
          forwardedRef={candidateNumberRef}
          name="scRegisterCandidateNumber"
          onChange={handleCandidateNameChange}
          onKeyDown={handleEnterKeyDown}
          placeholder="Candidate number"
          style={InputStyle.SECONDARY}
          value={candidateNumber}
        />
        <div>
          {isLoading && (
            <div className={styles.loading}>
              <LoadingSVG />
            </div>
          )}
          <Button.Generic
            disabled={isLoading ? true : false}
            forwardedRef={searchButtonRef}
            label="Search"
            onClick={handleSearchButtonClick}
            style={ButtonStyle.BRAND_BERRY}
          />
        </div>
      </div>
    </div>
  );
};

export default Filter;
