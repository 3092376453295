import React, { useState, useRef } from "react";

import { SCRItemType } from "modules/api/endpoints/scr";
import * as Button from "components/Button";

import * as styles from "./styles.module.scss";
import clsx from "clsx";

type SCREntryProps = {
  entry: SCRItemType;
};

const Entry: React.FC<SCREntryProps> = ({ entry }) => {
  const [toggleTableRow, setToggleTableRow] = useState(false);
  const [height, setHeight] = useState("0px");
  const className = clsx(toggleTableRow && styles.open);
  const ref = useRef<HTMLDivElement>(null);

  const handleToggleMenu = () => {
    setToggleTableRow(!toggleTableRow);
    setHeight(toggleTableRow ? "0px" : `${ref.current?.scrollHeight}px`);
  };

  return (
    <>
      <tr className={className}>
        <td>{entry.first_name}</td>
        <td>{entry.surname}</td>
        <td>{entry.candidate_number}</td>
        <td>{entry.schemeName}</td>
        <td>{entry.level}</td>
        <td>{entry.module}</td>
        <td>{entry.exam_date}</td>
        <td>
          <Button.Generic
            ariaLabel={
              toggleTableRow
                ? `Expand ${entry.first_name} ${entry.surname} row`
                : `Close $${entry.first_name} ${entry.surname} row`
            }
            onClick={handleToggleMenu}
          >
            <span></span>
          </Button.Generic>
        </td>
      </tr>

      <tr className={styles.scrExpanded}>
        <td colSpan={10}>
          <div ref={ref} style={{ maxHeight: `${height}` }}>
            <div>
              <span className={styles.label}>Exam Institute </span>
              {entry.exam_institute}
            </div>

            <div>
              <span className={styles.label}>ATO </span>
              {entry.ato}
            </div>

            <div>
              {entry.expiry_date && new Date(entry.expiry_date) < new Date() ? (
                <span className={styles.label}>Inactive </span>
              ) : (
                <span className={styles.label}>Renew By </span>
              )}
              {entry.expiry_date ? entry.expiry_date : "Not Provided"}
            </div>

            {entry.expiry_date
              ? new Date(entry.expiry_date) < new Date() && (
                  <div>
                    <span className={styles.label}>Note </span>
                    This certification can be renewed to align with current certification requirements
                  </div>
                )
              : null}
          </div>
        </td>
      </tr>
    </>
  );
};

export default Entry;
