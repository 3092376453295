import { useEffect } from "react";

type useResetFilterProps = {
  setRecords: (value: React.SetStateAction<number>) => void;
  setOffset: (value: React.SetStateAction<number>) => void;
  nameRef: React.RefObject<HTMLInputElement>;
  familyNameRef: React.RefObject<HTMLInputElement>;
  candidateNumberRef: React.RefObject<HTMLInputElement>;
  searchButtonRef: React.RefObject<HTMLButtonElement>;
  setReset: React.Dispatch<React.SetStateAction<boolean>>;
};

export const useResetFilter = ({
  setRecords,
  setOffset,
  nameRef,
  familyNameRef,
  candidateNumberRef,
  searchButtonRef,
  setReset,
}: useResetFilterProps) => {
  const resetFilters = () => {
    setRecords(20);
    setOffset(0);
    setReset(true);
  };
  useEffect(() => {
    nameRef &&
      nameRef.current &&
      nameRef.current.addEventListener("keydown", (event) => {
        event.key === "Enter" && resetFilters();
      });
    return () => {
      nameRef &&
        nameRef.current &&
        nameRef.current.removeEventListener("keydown", (event) => {
          event.key === "Enter" && resetFilters();
        });
    };
  }, [nameRef]);

  useEffect(() => {
    familyNameRef &&
      familyNameRef.current &&
      familyNameRef.current.addEventListener("keydown", (event) => {
        event.key === "Enter" && resetFilters();
      });
    return () => {
      familyNameRef &&
        familyNameRef.current &&
        familyNameRef.current.removeEventListener("keydown", (event) => {
          event.key === "Enter" && resetFilters();
        });
    };
  }, [familyNameRef]);

  useEffect(() => {
    candidateNumberRef &&
      candidateNumberRef.current &&
      candidateNumberRef.current.addEventListener("keydown", (event) => {
        event.key === "Enter" && resetFilters();
      });
    return () => {
      candidateNumberRef &&
        candidateNumberRef.current &&
        candidateNumberRef.current.removeEventListener("keydown", (event) => {
          event.key === "Enter" && resetFilters();
        });
    };
  }, [candidateNumberRef]);

  useEffect(() => {
    searchButtonRef && searchButtonRef.current && searchButtonRef.current.addEventListener("click", resetFilters);
    return () => {
      searchButtonRef && searchButtonRef.current && searchButtonRef.current.removeEventListener("click", resetFilters);
    };
  }, [searchButtonRef]);
};
