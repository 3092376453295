import React from "react";
import clsx from "clsx";

import { InputFormat, InputStyle, InputType } from "../constants";
import { InputPrimitiveProps } from "../types";
import InputIcon from "./icons";
import Label from "components/Label";

import * as styles from "./styles.module.scss";

const InputPrimitive: React.FunctionComponent<InputPrimitiveProps> = ({
  disabled,
  forwardedRef,
  format = InputFormat.TEXT,
  id,
  invalid,
  label,
  onMount,
  style = InputStyle.PRIMARY,
  type = InputType.TEXT,
  value,
  ...props
}) => {
  const className = clsx(
    styles.input,
    format && styles[`format-${format}`],
    style && styles[`${style}`],
    disabled && styles.disabled,
    !disabled && invalid && styles.invalid
  );
  return (
    <div className={className}>
      <InputIcon format={format} />
      <input disabled={disabled} id={id} ref={forwardedRef} type={type} value={value} {...props} />
      {label && style === InputStyle.PRIMARY && (
        <Label
          id={id}
          hasStickyLabel={style === InputStyle.PRIMARY}
          isSticky={value !== "" && style === InputStyle.PRIMARY}
          label={label}
        />
      )}
    </div>
  );
};

export default React.memo(InputPrimitive);
