import React from "react";

import { SCRItemType } from "modules/api/endpoints/scr";
import * as SCR from "components/SCR";

import * as styles from "./styles.module.scss";

export type SCRTableProps = {
  entries: SCRItemType[] | null;
  totalItems: number | null;
};

const Table: React.FC<SCRTableProps> = ({ entries, totalItems }) => {
  return entries && entries.length > 0 ? (
    <div className={styles.scrTable}>
      <div className="container small">
        <div>
          <p>
            Showing 1-{entries.length} of <span>{totalItems}</span>
          </p>
          <div className={styles.scrollContainer}>
            <table>
              <thead>
                <tr>
                  <td>First name</td>
                  <td>Last name</td>
                  <td>Candidate number</td>
                  <td>Certification</td>
                  <td>Level</td>
                  <td>Module</td>
                  <td>Exam date</td>
                  <td></td>
                </tr>
              </thead>
              <tbody>
                {entries.map((entry: SCRItemType) => {
                  return <SCR.Entry entry={entry} key={entry.uid} />;
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default Table;
