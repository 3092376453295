import React from "react";
import clsx from "clsx";

import * as tableStyles from "../Table/styles.module.scss";
import * as styles from "./styles.module.scss";

type SkeletonProps = {};

const Skeleton: React.FC<SkeletonProps> = () => {
  const className = clsx(tableStyles.scrTable, styles.loading);
  return (
    <div className={className}>
      <div className="container small">
        <div>
          <p className={styles.shimmer}></p>
          <div className={tableStyles.scrollContainer}>
            <table>
              <thead>
                <tr>
                  <td className={styles.shimmer}></td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td></td>
                </tr>
                <tr>
                  <td className={styles.shimmer}></td>
                </tr>
                <tr>
                  <td></td>
                </tr>
                <tr>
                  <td className={styles.shimmer}></td>
                </tr>
                <tr>
                  <td></td>
                </tr>
                <tr>
                  <td className={styles.shimmer}></td>
                </tr>
                <tr>
                  <td></td>
                </tr>
                <tr>
                  <td className={styles.shimmer}></td>
                </tr>
                <tr>
                  <td></td>
                </tr>
                <tr>
                  <td className={styles.shimmer}></td>
                </tr>
                <tr>
                  <td></td>
                </tr>
                <tr>
                  <td className={styles.shimmer}></td>
                </tr>
                <tr>
                  <td></td>
                </tr>
                <tr>
                  <td className={styles.shimmer}></td>
                </tr>
                <tr>
                  <td></td>
                </tr>
                <tr>
                  <td className={styles.shimmer}></td>
                </tr>
                <tr>
                  <td></td>
                </tr>
                <tr>
                  <td className={styles.shimmer}></td>
                </tr>
                <tr>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Skeleton;
