import { API, api } from "modules/api";
import { SCRSchemeGetResponse, SCRSchemeItemType } from "modules/api/endpoints/scr-scheme";

type useFetchScrSchemesDataProps = {
  setSCRSchemes: (value: React.SetStateAction<SCRSchemeItemType[] | null>) => void;
};

export const useFetchScrSchemesData = async ({ setSCRSchemes }: useFetchScrSchemesDataProps) => {
  // SCHEMES
  try {
    const scrSchemeResponse = await api(API.GET_SCR_SCHEMES()).then((res: SCRSchemeGetResponse) => res.data);
    if (scrSchemeResponse.schemes.length === 0) {
      // No schemes
      return;
    }
    // Successful
    setSCRSchemes(scrSchemeResponse.schemes);
  } catch (error) {
    // Error
  }
};
