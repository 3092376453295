import { SCRItemType } from "modules/api/endpoints/scr";

type useValidateFiltersProps = {
  activeCertifications: string[];
  name: string;
  familyName: string;
  candidateNumber: string;
  setIsLoading: (value: React.SetStateAction<boolean>) => void;
  setTotalRecords: (value: React.SetStateAction<number | null>) => void;
  setSCREntries: (value: React.SetStateAction<SCRItemType[] | null>) => void;
  setErrorMessage: (value: React.SetStateAction<string | null>) => void;
  emptyMessage: string;
  firstNameValidationMessage: string;
  setSkeleton: React.Dispatch<React.SetStateAction<boolean>>;
};

export const useValidateFilters = ({
  activeCertifications,
  name,
  familyName,
  candidateNumber,
  setIsLoading,
  setTotalRecords,
  setSCREntries,
  setErrorMessage,
  emptyMessage,
  firstNameValidationMessage,
  setSkeleton,
}: useValidateFiltersProps) => {
  const validate = () => {
    setIsLoading(true);
    if (!name && !familyName && !candidateNumber && activeCertifications.length === 0) {
      // No filter options set
      setTotalRecords(0);
      setSCREntries(null);
      setErrorMessage(emptyMessage);
      setTimeout(() => {
        setIsLoading(false);
        setSkeleton(false);
      });
      return false;
    }
    if (name && !familyName) {
      // Name provided without surname
      setTotalRecords(0);
      setSCREntries(null);
      setErrorMessage(firstNameValidationMessage);
      setTimeout(() => {
        setIsLoading(false);
        setSkeleton(false);
      });
      return false;
    }
    return true;
  };

  return { validate };
};
