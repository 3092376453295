import React, { useRef } from "react";

import Meta from "components/Meta";
import Layout from "components/Layout";
import LoadMoreButton from "components/LoadMoreButton";
import * as Hero from "components/Hero";
import { HeroColour, HeroSize } from "components/Hero/Banner";
import * as SCR from "components/SCR";
import { SCRTemplateProps } from "../types";
import * as ProductCards from "components/ProductCards";
import * as SixtyThreeDegreesBanner from "components/SixtyThreeDegreesBanner";
import NoResultsMessage from "components/NoResultsMessage";

const Template: React.FC<SCRTemplateProps> = ({
  defaultMeta,
  errorMessage,
  certifications,
  handleLoadMoreClick,
  hero_banner,
  filterProps,
  isLoading,
  location,
  mediumDegreesBanner,
  meta,
  productCards,
  resultsRef,
  scrEntries,
  skeleton,
  totalRecords,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const slider = productCards.enable_carousel;
  const moreThanOne = productCards?.products.length > 1;
  const productCardsWidget =
    slider && moreThanOne ? <ProductCards.Carousel {...productCards} /> : <ProductCards.Grid {...productCards} />;
  const showLoadMoreButton = scrEntries && totalRecords !== 0 && totalRecords !== scrEntries.length;

  return (
    <Layout>
      <Meta defaultMeta={defaultMeta} location={location} meta={meta} />
      <div>
        {hero_banner && (
          <Hero.Banner
            background_colour={HeroColour.BLACK_GRAPE}
            content={hero_banner.content}
            forwardedContainerRef={containerRef}
            hero_image={hero_banner?.image}
            hero_size={HeroSize.LARGE}
            image_horizontal_position={hero_banner.image_horizontal_position}
            overlay={true}
            reverseDivider={true}
          >
            <SCR.Filter
              certifications={certifications}
              forwardedContainerRef={containerRef}
              isLoading={isLoading}
              {...filterProps}
            />
          </Hero.Banner>
        )}
        <div className="scrollContainer" ref={resultsRef}>
          {skeleton && <SCR.Skeleton />}
          {totalRecords === 0 ? (
            <NoResultsMessage errorMessage={errorMessage} />
          ) : (
            !skeleton && <SCR.Table entries={scrEntries} totalItems={totalRecords} />
          )}
          {totalRecords !== null && (
            <>
              {showLoadMoreButton && <LoadMoreButton isLoading={isLoading} handleClick={handleLoadMoreClick} />}
              {productCards && productCardsWidget}
            </>
          )}
        </div>
        {mediumDegreesBanner && <SixtyThreeDegreesBanner.Medium {...mediumDegreesBanner} />}
      </div>
    </Layout>
  );
};

export default Template;
